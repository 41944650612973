import lbLogo from "../Assets/Projects/lb.png"
import magic from "../Assets/Projects/magic.png"
import github from "../Assets/Projects/github.png"
import lstm from "../Assets/Projects/lstm-stock.png"
import bst from "../Assets/Projects/BST.png"
import cell from "../Assets/Projects/cell.png"
import medium from "../Assets/Projects/medium.png"
import nsdi from "../Assets/Projects/nsdi25.png"

export const project_data = [
        {
                title: "Distributed System Paper",
                description: "2025 NSDI accepcted",
                link: "https://s3.us-east-2.amazonaws.com/teyenchou.com/NSDI__Pineapple.pdf",
                img: nsdi
        },
        {
                title: "Software engineer",
                description: "@ CytoAurora Biotechnologies",
                link: "https://www.cytoaurora.com/en",
                img: cell
        },
        {
                title: "Linking Book",
                description:"Social platform" ,
                link:"https://github.com/teyenc/linking-book",
                img: lbLogo
        },
        {
                title: "GitHub",
                description:"Data & Code",
                link:"https://github.com/teyenc",
                img: github
        },
        
        // {
        //         title: "Magic",
        //         description:"Basketball magic with 100k+ YouTube views",
        //         link:"https://www.youtube.com/watch?v=R9oZRiKPQmA&t=1s",
        //         img: magic
        // },
]

export const portfolio_data = [
        {
                title: "2025 NSDI accepted paper",
                description: "Pineapple: A system that unifies Multi-Paxos and atomic shared registers using logical timestamps to improve throughput and latency by offloading reads and writes to non-leader nodes, addressing bottlenecks in leader-based consensus systems",
                link: "https://s3.us-east-2.amazonaws.com/teyenchou.com/NSDI__Pineapple.pdf",
                img: nsdi
        },
        {
                title: "Linking Book",
                description:"Mobile social platform for people to share link, text, and images" ,
                link:"https://github.com/teyenc/linking-book",
                img: lbLogo
        },
        {
                title: "Binary Search Tree - TLA+",
                description:"TLA+ specification for a Binary Search Tree (BST) using a finite set of natural numbers. The implementation supports basic operations like insertion and deletion while maintaining BST properties." ,
                link:"https://github.com/teyenc/BinarySearchTree_TLAplus",
                img: bst
        },
        {
                title: "GitHub",
                description:"Go GitHub and see more projects....",
                link:"https://github.com/teyenc",
                img: github
        },
]

export const magic_imgs = [
        {
                img:"https://andy-website-image.s3.us-east-2.amazonaws.com/IMG_3735.JPG"
        },
        {
                img:"https://andy-website-image.s3.us-east-2.amazonaws.com/IMG_2523.JPG"
        },
        {
                img:"https://andy-website-image.s3.us-east-2.amazonaws.com/IMG_2698.JPG"
        },
        {
                img:"https://andy-website-image.s3.us-east-2.amazonaws.com/basketball.png"
        },
        // {
        //         img:
        // },
]

export const magic_profile = "https://andy-website-image.s3.us-east-2.amazonaws.com/14481798_1159521720796821_8125915069111156951_o.jpeg"